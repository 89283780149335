import React from 'react';
import LogoImage from '../src/Assets/Logo PNG-01.png';
import './App.css';
import { Link, Outlet } from 'react-router-dom';
import Courses from './components/Courses';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const App = () => {
  return (
    <div className="App">
      <header className="header">
        <div className="container">
          <img src={LogoImage} alt="Logo" className="logoimage" />
          <nav className="nav-links">
            <Link to="/about" className="link-item">
              About
            </Link>
            <Link to="/courses" className="link-item">
              Courses
            </Link>
            <Link to="/contact" className="link-item">
              Contact
            </Link>


            <Outlet />
          </nav>
        </div>
      </header>

      <div className="main-text">
        <div data-aos="fade-right"

          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false">
          <h1 className="welcome-text">
            Welcome to Ausland English
            <br />
            Your Gateway to Global Education!
          </h1>
        </div>
        <button className="get-started-btn">Get Started</button>
      </div>

      <Courses />
      <Contact />
    <Footer />
    </div>
  );
};

export default App;
