import React from 'react'
import './Contact.css'

function Contact() {
  return (
    <div className="ContactPage">
      <div className='box'>
        <div className='one'>
          <h3>Contact</h3>

        </div>
        <div className="contact-one" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
          <input type="text"placeholder='username'/>
          <input type="text"placeholder='Email'/>
          <input type="text"placeholder='Phone Number'/>
          <input type="text"placeholder='message'/>
          


          <button className='btn'>Sign In</button>            
       
        </div>


      </div>

    </div>


  )
}

export default Contact