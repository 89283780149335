import React from 'react';
import './Courses.css';

function Courses() {
  const courses = [
    { id: 1, title: 'Spoken English', instructor: 'grammar, fluency, and listening comprehension. Mastering spoken English allows individuals to effectively convey their thoughts, ideas, and emotions, fostering clear and meaningful communication in various contexts.', duration: '', image: 'https://www.schoolnetindia.com/blog/wp-content/uploads/2021/12/How-to-Improve-your-spoken-English-1-1-960x524.jpg' },
    { id: 2, title: 'OET', instructor: 'The OET offers healthcare professionals a comprehensive and reliable means of demonstrating their English language proficiency, enabling them to pursue career opportunities and deliver high-quality care in English-speaking environments.', duration: '', image: 'https://image.freepik.com/free-vector/flat-design-online-courses-illustration_23-2148528493.jpg' },
    { id: 3, title: 'IELTS', instructor: 'IELTS scores are widely accepted by universities, colleges, employers, immigration authorities, and professional bodies around the world.', duration: '', image: 'https://www.leadersinstitute.com/wp-content/uploads/2020/04/Online-course.png' },
    
    
  ];

  return (
    <div className="courses">
      <h2 className="courses-heading" >Courses</h2>
      <div className="courses-container">
        {courses.slice(0,3).map(course => (
          <div key={course.id} className="course-card">
            <img src={course.image} className="course-image" alt={course.title} data-aos="zoom-out-left"/>
            <div className="course-details">
              <h3 className="course-title" data-aos="fade-right">{course.title}</h3>
              <p className="course-instructor" data-aos="fade-right">: {course.instructor}</p>
              <p className="course-duration" data-aos="fade-left">: {course.duration}</p>
              <button className="course-button" data-aos="zoom-in-down"> Enroll Now</button>
            </div>
          </div>
        ))}
      </div>
     

    </div>
  );
}

export default Courses;