import React from "react";
import { EnvelopeIcon, MapIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/16/solid";
import { MdCopyright } from "react-icons/md";
import "./Footer.css";

const Footer = () => {
  return (<>
    <div className="footer-container">
      <div className="footer-sections">
        <div className="absolute">
          
          <h1 className="description">
          Effective spoken English communication involves not only fluency and clarity in speech but also factors such as intonation, stress, rhythm, and non-verbal cues like facial expressions and body language. Native speakers and proficient non-native speakers alike continuously refine their spoken English skills through practice, exposure to different language contexts, and interaction with diverse speakers.
          </h1>
          <p className="contact-info">AuslandEnglish@gmail.com</p>
        </div>
        <div className="quick-links">
          <h1 className="section-title">Quick Links</h1>
          <p className="link">Home</p>
          <p className="link">About</p>
          <p className="link">Service</p>
          <p className="link">Contact</p>             
        </div>
        <div className="contact-info">
          <h1 className="section-title">Address</h1>
          <div className="address">
            <MapPinIcon className="icon" />
            <p className="info">Smart Trade Citty Kottakkal, 2nd Floor, Near Kottakkal Bus Stand, Malappuram,Kerala</p>
          </div>
          <div className="address">
            <EnvelopeIcon className="icon" />
            <p className="info">AuslandEnglish@gmail.com</p>
          </div>
          <div className="address">
            <PhoneIcon className="icon" />
            <p className="info">+91 7034333499</p>
          </div>
        </div>
      </div>
      <div className="copyright">
        <MdCopyright />
        <p>Copyright Ausland EnglishWebsite 2024</p>
      </div>
    </div>
    </>
  );
};

export default Footer;
