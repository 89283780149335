import React from 'react'
import './About.css';

const About = () => {
  return (
    <div className='main'>
                   
      <div className='about-items'>

      <h2>about us</h2>

      <h3>Today, I want to chat about education and how we can make the most out of it. Education isn't just about sitting in classrooms and memorizing facts; it's about engaging with the world around us and learning from every experience.</h3>
      <h3>First off, let's talk about the importance of curiosity. Education thrives on curiosity. When we're curious, we're eager to explore new ideas, ask questions, and seek answers. So, embrace your curiosity! Don't be afraid to ask "why" or "how." Curiosity is the spark that ignites the fire of learning.</h3>
      <h3>Next, let's discuss the role of experimentation. Education isn't just about absorbing information; it's about experimenting and putting ideas into action. Whether it's conducting a science experiment or trying out a new language, hands-on learning can be incredibly powerful. So, roll up your sleeves and get ready to dive in!</h3>

        </div>



        
        




      </div>



    


  )
}

export default About