import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter and other necessary components
import About from './components/About';
import Contact from './components/Contact';
import Courses from './components/Courses';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes> {/* Use Routes instead of BrowserRouter directly */}
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/courses" element={<Courses />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);